import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
var text = colorUseCases.text;
export default css({
  fontSize: "$body",
  cursor: "pointer",
  whiteSpace: "pre",
  textWrap: "auto",
  flex: 1,
  marginBottom: 0,
  // Gentu global style override
  variants: {
    disabled: {
      "true": {
        cursor: "not-allowed",
        color: getColorScaleValueByUseCase("neutral", text.disabled)
      }
    },
    direction: {
      top: {
        textAlign: "center"
      },
      bottom: {
        textAlign: "center"
      },
      left: {
        textAlign: "right"
      },
      right: {
        textAlign: "left"
      }
    }
  }
});