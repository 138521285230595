import { colorUseCases, css, getColorScaleValueByUseCase } from "@vitality-ds/system";
import SIZE from "../constants/size";
import TRANSITION from "../constants/transitions";
var text = colorUseCases.text,
  backgrounds = colorUseCases.backgrounds;
var THUMB = SIZE.THUMB,
  INSET_SPACING = SIZE.INSET_SPACING,
  TRACK_WIDTH = SIZE.TRACK_WIDTH;
var DURATION_MS = TRANSITION.DURATION_MS,
  TIMING_FUNCTION = TRANSITION.TIMING_FUNCTION;
export default css({
  position: "absolute",
  left: 0,
  width: THUMB,
  height: THUMB,
  borderRadius: "$rounded",
  transition: "transform ".concat(DURATION_MS, "ms ").concat(TIMING_FUNCTION),
  transform: "translateX(".concat(INSET_SPACING, "px)"),
  willChange: "transform",
  backgroundColor: getColorScaleValueByUseCase("neutral", text.onSolidBackgrounds),
  "&[data-disabled]": {
    backgroundColor: getColorScaleValueByUseCase("neutral", backgrounds.uiElement)
  },
  variants: {
    checked: {
      "true": {
        transform: "translateX(".concat(TRACK_WIDTH - THUMB - INSET_SPACING, "px)"),
        "&[data-disabled]": {
          backgroundColor: getColorScaleValueByUseCase("neutral", text.disabled)
        }
      }
    }
  }
});