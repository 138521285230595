import _typeof from "@babel/runtime/helpers/typeof";
import _extends from "@babel/runtime/helpers/extends";
import CustomClearButton from "./components/ClearButton";
import CustomControl from "./components/Control";
import CustomDropdownIndicator from "./components/DropdownIndicator";
import CustomInput from "./components/Input";
import CustomLoadingIndicator from "./components/LoadingIndicator";
import CustomRenderedOption from "./components/Option";
import CustomSingleValue from "./components/SingleValue";
import { CUSTOM_COMPONENTS } from "./constants";
import { multiValueStyleOptions, styles } from "./styled";
export function getStyles(multiSelectType) {
  return _extends({}, styles, multiValueStyleOptions[multiSelectType]);
}
export function getCustomComponents(_ref) {
  var hideDropdownIndicator = _ref.hideDropdownIndicator,
    multiSelectType = _ref.multiSelectType,
    _ref$component = _ref.component,
    component = _ref$component === void 0 ? "select" : _ref$component,
    _ref$valueRenderType = _ref.valueRenderType,
    valueRenderType = _ref$valueRenderType === void 0 ? "default" : _ref$valueRenderType;
  var customComponents = _extends({
    DropdownIndicator: !hideDropdownIndicator && CustomDropdownIndicator,
    ClearIndicator: CustomClearButton,
    LoadingIndicator: CustomLoadingIndicator,
    Option: CustomRenderedOption,
    SingleValue: CustomSingleValue
  }, CUSTOM_COMPONENTS[multiSelectType]);
  if (component === "searchSelectInput") {
    Object.assign(customComponents, {
      Control: CustomControl
    });
  }
  if (valueRenderType === "typeahead") {
    Object.assign(customComponents, {
      Input: CustomInput
    });
  }
  return customComponents;
}
export var getValueObjectFromOptionsByValueString = function getValueObjectFromOptionsByValueString(valueString, selectOptions) {
  var findValueObject = function findValueObject(options) {
    return options.reduce(function (result, option) {
      return result || (option.value === valueString ? option : findValueObject(option.options || []));
    }, null);
  };
  return findValueObject(selectOptions) || null;
};
export var getValueObject = function getValueObject(value, options, selectType) {
  if (!options) return null;
  var valueObject = options && value instanceof Object ? value : getValueObjectFromOptionsByValueString(value, options);
  if (selectType === "CreatableSelect" && !valueObject) {
    valueObject = {
      label: value,
      value: value
    };
  }
  return valueObject;
};
export var getSafeValue = function getSafeValue(value, options, isMulti) {
  var selectType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "Select";
  if (options && isMulti && value instanceof Array) {
    return value.map(function (optionString) {
      return getValueObject(optionString, options, selectType);
    });
  }
  return getValueObject(value, options, selectType);
};
export var getSafeInputValue = function getSafeInputValue(value, inputValue, valueRenderType) {
  if (valueRenderType === "typeahead") {
    if (_typeof(value) === "object") {
      return (value === null || value === void 0 ? void 0 : value.label) || (value === null || value === void 0 ? void 0 : value.value);
    }
    return value;
  }
  return inputValue;
};
export var isBrowser = function isBrowser() {
  return typeof document !== "undefined";
};