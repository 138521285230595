import useAutocomplete from "@mui/material/useAutocomplete";
import { Index } from "elasticlunr";
import { navigate } from "gatsby";
import { useCallback, useEffect } from "react";

import useLocalState from "../../../../../hooks/useLocalState";
import { getOnInputChange, onLoadIndex } from "../logic";
import { SearchIndex, UseAutocomplete, UseSearchField } from "../types";

const useSearchField = (searchIndex: SearchIndex): UseSearchField => {
  const [query, setQuery] = useLocalState("");
  const [results, setResults] = useLocalState([]);
  const [index, setIndex] = useLocalState(onLoadIndex(Index, searchIndex));

  useEffect(() => setIndex(onLoadIndex(Index, searchIndex)), [searchIndex]);

  const onInputChange = useCallback(
    (evt) => {
      if (!evt) {
        return;
      }
      getOnInputChange(evt.target.value, setQuery, setResults, index);
    },
    [setQuery, setResults, index]
  );

  const isOptionEqualToValue = useCallback(
    (option: { path: string }, value: { path: string }) => {
      return option.path === value.path;
    },
    []
  );

  const getOptionLabel = useCallback(
    (option: { title: string }) => option.title || "",
    []
  );

  const onChange = useCallback((_, value: { path?: string }) => {
    if (value?.path) {
      return navigate(value.path);
    }
    return null;
  }, []);

  const groupBy = useCallback(
    (option: { category: string }) => option.category,
    []
  );

  // Material-UI's Autocomplete for the search results
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "vitality-docs",
    options: results,
    isOptionEqualToValue,
    getOptionLabel,
    onInputChange,
    onChange,
    groupBy,
    autoHighlight: true,
    blurOnSelect: true,
    freeSolo: true,
    onClose: () => setQuery(""),
    inputValue: query,
  }) as unknown as UseAutocomplete;

  const onFocus = useCallback(
    (e: KeyboardEvent): void => {
      const { ref } = getInputProps();
      if (!ref.current) return;

      ref.current.focus();
      e.preventDefault();
    },
    [getInputProps]
  );

  const keyMap = { SHOW_ALL_HOTKEYS: "command+k" };
  const handlers = { SHOW_ALL_HOTKEYS: onFocus };

  return {
    query,
    keyMap,
    handlers,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  };
};

export default useSearchField;
