import { DateTime } from "luxon";
import { APPLICATION_ICON_TRIGGERS, BRAND_ICONS, TITLES_TO_IGNORE } from "./constants";
export var getTimes = function getTimes(timestamp) {
  if (!timestamp) {
    return {
      date: null,
      time: null,
      humanTime: null,
      invalidTime: null
    };
  }
  var dateTime = DateTime.fromISO(timestamp.replace(" ", "T"));
  // need to express date in dd/MM/yyyy format explicitly instead of relying on locale because windows 11 prevents luxon from reading language settings -> this becomes problematic because luxons default is US
  var date = dateTime.toFormat("dd/MM/yyyy");
  // needs to be 'en-US' format to show in AM/PM 12 hour time where 'en-GB' likes 24-hour time.
  var time = dateTime.toFormat("tt", {
    locale: "en-US"
  });
  var humanTime = dateTime.toRelative();
  return {
    date: date,
    time: time,
    humanTime: humanTime,
    invalidTime: dateTime.invalid !== null
  };
};
export var getBrand = function getBrand(name) {
  if (!name) return undefined;
  var normalised = name.toLowerCase();
  var brand = APPLICATION_ICON_TRIGGERS.find(function (trigger) {
    return normalised.includes(trigger);
  });
  return BRAND_ICONS[brand];
};
var removeNonLettersFromInitials = function removeNonLettersFromInitials(initials) {
  return initials.replace(/[^a-zA-Z]+/g, "");
};
export var getInitials = function getInitials(name, forcedInitials) {
  if (forcedInitials) {
    return forcedInitials;
  }
  if (!name) {
    return null;
  }
  var initials = name.split(" ").filter(function (word) {
    return word && !TITLES_TO_IGNORE.includes(word.toLowerCase());
  }).map(function (word) {
    return word && word[0].toUpperCase();
  }).join("");
  if (initials.length > 2) {
    return null;
  }
  return removeNonLettersFromInitials(initials);
};